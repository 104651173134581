.preview {
  // -webkit-transform: translate3d(0, 0, 0);
  // font-family: 'Barlow', sans-serif;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .playBar {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 6px;

    button {
      height: 36px;
      width: 36px;
    }
  }
  .react-grid-item.react-grid-placeholder {
    background-color: #6b6b6b !important;
  }
  .play-button {
    height: 36px;
    width: 36px;
  }
  .topbar {
    position: relative;
    left: 0;
    top: 0;
    height: 54px;
    background: #242424;
    border-bottom: 1px solid #353535;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    gap: 20px;
    overflow: hidden;

    span {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #b9b9b9;
      margin: 0;
    }
  }

  .right-arrow {
    padding: 0px 10px 0px 0px;
    position: relative;
    top: 0px;
    transform: rotate(180deg);
    width: 11px;
    height: 11px;
  }

  button:hover > .right-arrow {
    > path {
      fill: white;
    }
  }
  .right-arrow:hover {
    > path {
      fill: white;
    }
  }

  .left-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .grid {
    background-color: #1b1b1b;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: stretch;

    // mediaquery 700
    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    flex-direction: row;
    justify-content: center;

    h1 {
      color: white;
      margin: 20px;
      // italic
      // font-style: italic;
    }
  }
}
