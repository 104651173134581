@import '../assets/variables';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
@mixin respond-medium {
  @media (min-width: 1600px) {
    @content;
  }
}

.chrome-picker {
  width: 236px !important;
  margin-bottom: 8px;
  background-color: #2b2b2b !important;
  font-family: 'Roboto', sans-serif !important;
  border-radius: 4px !important;

  svg {
    fill: white !important;
    background: transparent !important;
  }
  svg:hover {
    fill: white !important;
    background: #323232 !important;
  }

  input {
    background-color: #313131 !important;
    outline: 1px solid #747474 !important;
    color: white !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-size: 12px !important;
  }

  div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > div {
    border-radius: 4px !important;
    background-color: white !important;
  }

  div:nth-child(2)
    > div:nth-child(1)
    > div:nth-child(2)
    > div:nth-child(2)
    > div
    > div:nth-child(2) {
    border-radius: 4px !important;
  }

  .hue-horizontal {
    border-radius: 4px !important;
  }
}

.playBar {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 6px;

  button {
    height: 36px;
    width: 36px;
  }
}

body {
  overflow: hidden;
}
li.nestable-item {
  margin-top: 0;
}
.nestable-container {
  position: relative;
  max-height: 100%;
}
.nestable-container-no-overflow {
  position: relative;
  max-height: 100%;
  overflow-y: hidden !important;
}
.nestable-multiselec {
  background-color: yellow !important;
}
.nestable-item-background {
  background-color: transparent !important;
}

.nestable-list {
  padding: 0;
  margin: 0 !important;
}
.group-nested-list {
  padding: 0 0 0 10px !important;
  background-color: rgba(71, 146, 226, 0.1) !important;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
}

#studio-editor {
  height: 100%;
  color: #ffffff;
  width: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }

  .align-center {
    align-items: center;
  }
  // .column {
  //   flex-flow: column;
  // }

  .text-align-left {
    text-align: left !important;
  }
  // .animation-input-small {
  //   width: 70px;
  // }
  //  .margin-right-pointer {
  //    cursor: pointer;
  //    margin-right: 10px;
  //  }

  .margin-top-small {
    margin-top: 15px;
  }
  // .margin-top {
  //   margin-top: 20px;
  // }

  // .text-tiny {
  //   font-size: 9px;
  //   text-align: center;
  //   margin-top: 7px;
  //   margin-bottom: 0;
  // }
  .half-width {
    width: 47%;
  }
  //  .text-small {
  //    font-size: 11px;
  //    margin: 0;
  //    @include respond-medium {
  //      font-size: 12px;
  //    }
  //  }

  .template-title {
    margin-left: 25px;
    font-size: 13px;
  }
  // .text-middle {
  //   font-size: 13px;
  //   margin: 0;
  //   color: #9e9e9e;
  // }

  //  .color-white {
  //    color: #ffffff !important;
  //  }
  .flex-space-between {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 10px;
  }

  .main-nav {
    width: 67px;

    background: $default-gray;
    z-index: 2;
    border-right: 1px solid $border-gray;
    display: flex;
    flex-direction: column;
  }

  .button {
    width: 130px;
    height: 33px;
    color: #8b8b8b;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 12px;
    line-height: 17px;
    margin-left: 10px;
  }

  .template-settings-section {
    padding: 17px;
    text-align: left;
  }
  .template-settings-input {
    box-sizing: border-box;
    width: 210px;
    height: 30px;
    margin-bottom: 8px;
    padding-left: 10px;
    text-align: left;
    font-size: 11px;
    background-color: $light-medium-gray;
    outline: none;
    border: none;
    color: white;
  }

  .settings-submenu {
    padding: 0px 0px 0px 0px !important;
    margin: 0px !important;
    width: auto;
    left: -143px;
    top: -31px;
    position: relative;
    height: auto;
    display: block;
    align-items: center;
  }

  .menu-title {
    color: white;
    font-size: 13px;
    margin-bottom: 20px;
  }
  .save-button {
    width: 130px;
    background-color: $zuuvi-blue;
    color: white;
  }
  /* left navigation */
  .zuuvi-logo {
    display: flex;
    margin: 7px auto 4px;
  }
  .left-nav-wrapper {
    display: flex;
    width: 250px;
  }

  .left-nav {
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;

    > :nth-child(2) {
      margin-top: 20px;
    }
  }

  .add-dco-button-container {
    text-align: center;
    padding: 18px;
    width: 100%;
  }

  .add-dco-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 54px;
  }

  .add-dco-button {
    width: 100%;
    height: 33px;
    left: 81px;
    top: 82px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b049ef;
    p {
      line-height: 12px;
    }
  }

  .add-dco-button-disabled {
    width: 100%;
    height: 33px;
    left: 81px;
    top: 82px;
    border: 1px solid rgba(83, 83, 83, 0.5);
    color: rgba(150, 150, 150, 0.5);
    box-sizing: border-box;
    border-radius: 4px;

    font-size: 12px;
    line-height: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }

  .dco-select-wrapper {
    width: 90%;
    margin: 10px 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dco-select-icon-name-wrapper {
    display: inline-flex;
    align-items: center;
    width: inherit;
    overflow: hidden;
    white-space: nowrap;
  }

  .dco-select-icon {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  .dco-select-text {
    font-size: 12px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.7);

    overflow: hidden;
    width: 80%;
    white-space: nowrap;
  }

  .dco-select-field-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 300px;
    overflow: scroll;
  }

  .dco-select-field-list-item {
    padding: 15px;
    font-size: 11px;

    color: #ffffff;
    border-bottom: 1px solid $border-gray;

    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &.active {
      background: #b049ef;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.03);
    }
  }

  .eye-icon-dco {
    cursor: pointer;
  }

  .trash-icon-dco {
    margin-left: 10px;
  }

  /* large dco menu */
  .large-dco-menu {
    /* Rectangle 248 */

    position: absolute;
    width: 999px;
    height: 904px;
    left: 0px;
    top: -2px;

    background: $default-gray;
    border: 1px solid $border-gray;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.03);
  }

  /* lef tabs*/
  .left-tab-open {
    position: absolute;
    width: 269px;
    height: 100%;
    z-index: 9;
    left: 68px;
    top: 0px;
    background: $default-gray;
    border-right: 1px solid $border-gray;
    box-shadow: 25px 0px 35px 0px rgba(0, 0, 0, 0.25);
    // overflow: scroll;
    overflow: hidden;

    .scroll-container {
      height: calc(100% - 36px);
    }
  }

  .close-cross-icon-wraper {
    border-bottom: 1px solid $border-gray;
    height: 35px;
    top: 0;
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .add-shape-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .add-shape-button {
    width: 45px;
    height: 46px;

    margin-bottom: 5px;
    border: 1px solid $light-gray;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .icon-small {
    height: 20px;
  }

  .left-nav-bottom-buttons {
    width: 100%;
    position: absolute;
    bottom: 5px;
  }
  .icon-link {
    color: white;
    text-decoration: none;
  }
  /* right tabs */

  .right-nav-wrapper {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    right: 0px;
    top: 55px;
    display: flex;
    height: calc(100vh - 55px);
    background: $default-gray;
    border-left: 1px solid $border-gray;
  }

  .right-nav {
    height: calc(100vh - 56px);
  }
  .right-nav-opened {
    width: 270px;
    height: 100%;
    overflow-y: auto;
    @include respond-medium {
      width: 270px;
    }
  }

  .fake-border {
    width: 1px;
    height: 100%;
    background-color: $border-gray;
  }
  // .add-shape-button:hover {
  //   background-color: #2c2c2c;
  // }

  .attributes-input-wrapper {
    height: 30px;
    display: flex;
    width: 80px;
    justify-content: space-between;
    padding: 0px 10px;
    background-color: #313131;
    margin: 5px 0px;
    @include respond-medium {
      width: 90px;
    }
  }

  // .attributes-input-small {
  //   width: 40px;
  //   @include respond-medium {
  //     width: 33px;
  //   }
  // }

  .description-text {
    color: #747474;
    font-size: 11px;
    font-style: italic;
    align-self: center;
    @include respond-medium {
      font-size: 13px;
    }
  }

  input[type='range'] {
    width: 100%;
    background: white;
    -webkit-appearance: none;
    margin: 0;
    height: 1px;
    outline: none;
    margin-right: 15px;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background: white;
      margin-top: 0px;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      border: none;
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background: white;
      margin-top: 0px;
    }
  }

  // .checkbox-wrapper {
  //   margin-left: 30px;
  //   align-self: flex-end;
  // }

  // select.select-dco-input {
  //   width: 207px;
  //   height: 30px;
  //   background-color: $medium-gray;
  //   border-radius: 1px;
  //   color: white;
  //   padding: 0px 10px;
  //   border: none;
  //   outline: none;
  // }

  // select.select-image-behaviour-input {
  //   width: 207px;
  //   height: 30px;
  //   background-color: $light-medium-gray;
  //   border-radius: 1px;
  //   color: white;
  //   padding: 0px 10px;
  //   border: none;
  //   outline: none;
  // }

  .padding-container {
    padding: 18px;
  }

  button.align-text-button {
    width: 30px;
    height: 30px;
    border-radius: 1px;
    margin-left: 8px;
  }

  /* banner */

  .canvas {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .canvas-actions {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  // .banner-container {
  //   position: relative;
  //   z-index: 1;
  // }
  #banner {
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
    line-height: 1.2;
  }
  // .left-tab-title {
  //   text-align: center;
  // }

  /* timeline */
  .timeline-container {
    overflow: auto;
    background-color: $default-gray;
    position: absolute;
    display: inline-grid;
    margin-left: 68px;
    bottom: 0;
    z-index: 3;
  }
  .add-folder {
    height: 16px;
    margin-right: 2px;
    cursor: pointer;
  }
  .timeline-wrapper {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .timeline-header {
    height: 25px;
    display: flex;
    z-index: 2;
    width: 100%;
  }
  .timeline-title {
    background-color: $light-medium-gray;
    height: 25px;
    width: 175px;
    display: flex;
    flex: none;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px 0px 13px;
  }
  .timeline-timescale {
    background-color: $menu-gray;
    height: 100%;
    color: #888888;
    width: 100%;
    display: flex;
    padding-left: 1px;
    position: relative;
  }
  .timeline-second-container {
    width: 100px;
    font-size: 8px;
    display: flex;
  }

  .styledMarkers {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 5px;
    width: 5px;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid hsl(180, 100%, 20%);
    border-radius: 1px;
    pointer-events: none;
  }
  .invisibleMarker {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
  }

  .layer-name-input {
    width: 90px;
    border: none;
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid $border-lighter-gray;
    color: white;
    font-size: 11px;
  }

  // .animation-wrapper {
  //   display: flex;
  //   align-items: center;
  //   height: 50px;
  //   margin-left: 1px;
  //   background-color: $default-gray;
  // }
  // .timeline-layer-light-line {
  //   height: 25px;
  //   width: 100%;
  //   position: relative;
  //   margin: auto;
  //   background-color: #2a2a2a;
  // }
  // div.animation {
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 4px;
  //   z-index: 2;
  //   background-color: $light-medium-gray;
  //   position: absolute;
  //   display: flex;
  //   align-items: center;
  // }

  //NOT SURE
  // .left {
  //   left: -3.5px;
  // }
  // .right {
  //   right: -2.5px;
  // }
  //

  .scroll-container {
    height: 100%;
    width: 100%;
    overflow: auto;

    &:not(.horizontal-scroll) {
      /* this will hide the scrollbar in mozilla based browsers */
      overflow: -moz-scrollbars-none;
      /* this will hide the scrollbar in internet explorers */
      -ms-overflow-style: none;

      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }
  }

  #timeline-play-marker {
    cursor: move;
    position: absolute;
    top: 3px;
    z-index: 4;
    left: -4px;
    bottom: 0;
  }
  .resize-timeline-container {
    width: 100%;
    background-color: $zuuvi-blue;
    height: 4px;
    cursor: row-resize;
    top: 0;
    right: 0;
    opacity: 0;
    position: sticky;
    z-index: 3;
    &:hover {
      opacity: 0.5;
    }
  }
  .play-animation-wrapper {
    position: absolute;

    left: 195px;
    height: 100%;
  }
  .animation-start-handle {
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border: 2px solid $zuuvi-blue;
    border-radius: 10px;
  }
  .animation-start-line {
    left: 6px;
    height: calc(100% - 17px);
    position: absolute;
    border: 1px solid $zuuvi-blue;
  }

  #timeline-end-marker {
    position: absolute;
    top: 3px;
    z-index: 4;
    bottom: 0;
    /* left: 191px; */
  }
  .end-animation-wrapper {
    position: absolute;
    left: 191px;
    height: 100%;
  }
  .timeline-end-handle {
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border: 2px solid #4a4a4a;
  }
  .timeline-end-line {
    left: 6px;
    height: calc(100% - 17px);
    position: absolute;
    border: 1px solid #4a4a4a;
  }

  #timeline-from-marker {
    position: absolute;
    top: -2px;
    z-index: 1;
    bottom: 0;
    width: 14px;
    /* left: 191px; */
  }
  .timeline-from-handle {
    position: absolute;
    width: 24px;
    height: 24px;
    left: -5px;
    box-sizing: border-box;
    border: none;
  }
  .timeline-from-line {
    left: 5.5px;
    top: 20px;
    height: calc(100% - 17px);
    position: absolute;
    border: 1px dashed #505050;
  }

  /* bottom tabs */
  // .bottom-section-wrapper {
  //   width: 100%;
  //   background-color: #444444;
  //   height: 150px;
  //   position: absolute;
  //   bottom: 0;
  // }

  .image-option {
    margin: 0px;
    width: 90px;
    height: 90px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 1px solid black;
    background-color: #d1d1d1;
    position: relative;
  }

  /* Basic button styling */

  .pulsingButton {
    box-shadow: 0 0 0 0 #4792e2;
    // background-color: #4792E2;
    // left: 18.6px;
    // position: relative;
    // top: -35.7px;
    width: 19px;
    height: 19px;
    border-radius: 4px;
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
  }

  /* Comment-out to have the button continue to pulse on mouseover */

  a.pulsingButton:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
    color: #ffffff;
  }

  /* Animation */

  @-webkit-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
      background-color: rgba(71, 146, 226, 0);
    }
  }

  @-moz-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
      background-color: rgba(71, 146, 226, 0);
    }
  }

  @-ms-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
      background-color: rgba(71, 146, 226, 0);
    }
  }

  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
      background-color: rgba(71, 146, 226, 0);
    }
  }

  .dco-clicktag-key-button-dynamic {
    background-color: #3e3e3e;
    border-radius: 3px;
    font-size: 11px;
    width: 112px;
    margin-bottom: 10px;
    color: #fff;
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dco-clicktag-key-button-parameter {
    background-color: #3e3e3e;
    border-radius: 3px;
    font-size: 11px;
    width: 112px;
    margin-bottom: 10px;
    color: #fff;
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dco-clicktag-key-button-custom {
    background-color: #3e3e3e;
    border-radius: 3px;
    font-size: 11px;
    width: 100%;
    color: #fff;
    margin: 3px 2px;
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dco-clicktag-inline-button-active {
    background-color: rgba(71, 146, 226, 0.9);
    border-radius: 3px;
    color: white;
    margin: 0px 1px;
    padding: 0px 2px 1px 2px;
  }

  .dco-clicktag-inline-button-inactive {
    background-color: rgba(255, 0, 0, 0.9);
    border-radius: 3px;
    color: white;
    margin: 0px 1px;
    padding: 0px 2px 1px 2px;
  }

  .dco-clicktag-error-message {
    color: rgba(255, 0, 0, 1);
  }

  .dco-clicktag-key-wrapper {
    display: flex;
    width: 100%;

    &::after {
      content: '';
      flex: auto;
    }
  }

  .animations-comming-soon-banner {
    width: 100%;
    height: 250px;

    background: #4792e2;
    border-radius: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 250px;

    user-select: none;
  }

  .animations-comming-soon-text {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    margin: 0;
    color: #000000;
  }

  .animations-comming-soon-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    margin: 0;
    color: #ffffff;
  }

  .drag-n-drop-zone-inactive {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
  }

  .drag-n-drop-zone-active {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

// on-off switch
.wrg-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.wrg-toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.wrg-toggle-check,
.wrg-toggle-uncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.wrg-toggle-check {
  left: 8px;
}
.wrg-toggle-uncheck {
  opacity: 1;
  right: 10px;
}

.wrg-toggle-uncheck span,
.wrg-toggle-check span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  position: relative;
  width: 10px;
}

.wrg-toggle-container {
  width: 50px;
  height: 21px;
  padding: 0;
  font-size: 11px;
  border-radius: 30px;

  transition: all 0.2s ease;
}

.wrg-toggle-circle {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 17px;
  height: 17px;

  border-radius: 50%;

  box-sizing: border-box;
  transition: all 0.25s ease;
}

.wrg-toggle--checked .wrg-toggle-check {
  opacity: 1;
}
.wrg-toggle--checked .wrg-toggle-uncheck {
  opacity: 0;
}
.wrg-toggle--checked .wrg-toggle-circle {
  left: 31px;
}

// LOADER
.loaderImage {
  height: 150px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -75px 0 0 -45px;
}
#loader-wrapper {
  position: fixed;
  background-color: #1b1b1b;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 300px;
  margin: -150px 0 0 -150px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $zuuvi-blue;

  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #df6d4e;

  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #69d9ad;

  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
