@import '../../assets/variables';

.top-header {
  position: absolute;
  width: calc(100% - 68px);
  height: 54px;
  left: 68px;
  top: 0px;
  background: $default-gray;
  border-bottom: 1px solid $border-gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
}

.top-menu-left-section {
  display: flex;
  margin-left: 20px;
  height: 100%;
  align-items: center;
}

.dashboard-link {
  text-decoration: none;
  color: #b9b9b9;
}

.top-menu-right-section {
  display: flex;
  margin-right: 20px;
  height: 100%;
  align-items: center;
}

.animation-button {
  &:hover {
    fill: $zuuvi-blue;

    &:active {
      fill: $zuuvi-blue;
    }
  }
}

.timeline-progress-container {
  width: 78px;
  .timeline-progress-container-text {
    font-size: 13px;
    margin: 0px 0px 0px 15px;
    color: #c0c0c0;
  }
}

.top-menu-right-section-box {
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;
  height: 50px;
}

.margin-right-small-pointer {
  cursor: pointer;
  margin-right: 5px;
}

.zoom-button {
  height: 50px;
  width: 16px;
  display: flex;
  align-items: center;
}

.zoom-text {
  text-align: end;
  width: 32px;
  margin-left: 5px;
}

.button-margin-left-small-pointer {
  cursor: pointer;
  margin-left: 5px;
}

.settings-menu {
  position: absolute;
  width: 165px;
  z-index: 4;

  background-color: #242424;
  right: 30px;
  top: 54px;
  margin: 0px;
  border: 1px solid #3c3c3c;
  list-style-type: none;
  padding: 10px 3px 10px 0px;
  .settings-arrow {
    display: initial;
  }
  > li:hover {
    background-color: $zuuvi-blue;
  }
}

.settings-menu-item {
  height: 30px;
  align-items: center;
  width: 140px;
  border-radius: 2px;
  margin: auto;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      display: none;
    }
  }
  p {
    line-height: 30px;
    font-size: 13px;
    margin: 0px 0px 0px 10px;
  }
}
