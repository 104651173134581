.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button {
  margin-bottom: 15px;
}
