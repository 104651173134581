@import './../../assets/variables';

.left {
  left: -3.5px;
}

.right {
  right: -2.5px;
}

.animation-blue-line-icon {
  top: 4px;
  position: absolute;
}

div.animation {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
  background-color: $light-medium-gray;
  position: absolute;
  display: flex;
  align-items: center;
}

.animation-name {
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}
