@import '../../assets/variables';

@mixin respond-medium {
  @media (min-width: 1600px) {
    @content;
  }
}

.attributes-show-hide-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  font-size: 14px;
  border-top: 1px solid $border-gray;
  border-bottom: 1px solid $border-gray;
  width: 100%;
  background-color: $medium-gray;
  padding: 0px 10px;
  font-weight: 600;
}
