#assets {
  // height: calc(100% - 36px - 55px);
  padding: 10px;
  box-sizing: border-box;

  &.no-padding {
    padding: 0px;
  }

  .image-assets-images-wrapper {
    width: calc(100% + 10px);
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 42px - 15px);
    overflow: hidden;
    margin: 15px -5px 0px;
    align-content: flex-start;
  }

  .image-assets-image-container {
    padding-bottom: calc(50% - 10px);
    margin: 5px;
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    background: #d1d1d1;
    position: relative;

    .bg-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  #upload-asset {
    cursor: pointer;

    input {
      display: none;
    }

    label {
      font-size: 12px;
    }

    width: 100%;
  }
}
