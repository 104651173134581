.nestable-list-item {
  display: flex;
  flex-direction: row;
  // background-color: #2a2a2a;
  margin: 0 !important;
}

.timeline-layer-name {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  border-bottom: 1px solid #7a7a7a;
}
.timeline-layer-name-icon-left {
  display: flex;
  padding: 10px 0px 10px 10px;
  align-items: center;
  flex-basis: 150px;

  & div {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.timeline-layer-animations-container {
  width: 100%;
}
