.grid-wrapper {
  .ad {
    height: 100%;
    text-align: left;
  }

  .resize-button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;

    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    text-shadow: 0px 0px 3px black;
    z-index: 100;
  }

  .banner-size-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    padding-right: 5px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    text-shadow: 0px 0px 3px black;
    pointer-events: none;
  }
}
