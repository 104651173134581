// Variables
$backgroundColorPrimary: rgb(27, 27, 27);
$backgroundColorSecondary: #242424;
$textColorPrimary: #fff;
$textColorSecondary: #b9b9b9;
$borderColor: #353535;
$zuuviBlue: #4792e2;
$zuuviRed: #df6d4e;

#studio-editor {
  .publish-template-button {
    background-color: $zuuviBlue;
  }

  .format-create-options-list {
    position: absolute;
    right: 10px;
    top: 193px;
    width: 180px;
    background: #2a2a2a;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.4);
    z-index: 9;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    font-size: 11px !important;

    .format-option-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 33px;
      line-height: 22px;
      text-align: center;
      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      &:last-child {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }

      &.delete:hover {
        background-color: $zuuviRed;
      }
      &:hover {
        background-color: $zuuviBlue;
      }
    }
    p {
      margin: 0px;
      width: 100%;
    }
  }

  #formats,
  #add-format {
    .format-button {
      position: relative;
      width: 100%;
      height: 37px;
      border-bottom: 1px solid #353535;
      box-sizing: border-box;
      text-align: left;
      font-size: 11px;
      line-height: 13px;
      p {
        margin-left: 15px;
      }
    }
    .format-options {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 100%;

      color: #fff;

      .format-options-button {
        position: absolute;
        top: 11px;
        right: 10px;
      }

      .format-options-list {
        position: absolute;
        right: 0;
        top: 27px;
        width: 145px;
        background: #2a2a2a;
        box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.4);
        z-index: 9;
        border-radius: 4px;
        overflow: hidden;

        .format-option-item {
          display: flex;
          align-items: center;
          height: 33px;
          line-height: 22px;
          padding-left: 10px;
          &:first-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          }

          &:last-child {
            border-top: 1px solid rgba(0, 0, 0, 0.2);
          }

          &.delete:hover {
            background-color: $zuuviRed;
          }
          &:hover {
            background-color: $zuuviBlue;
          }
          p {
            margin: 0px;
          }
        }
      }
    }
  }

  #formats-header {
    border-top: 1px solid #535353;
    font-size: 20px;
    padding: 10px;
  }

  #create-format-container {
    * {
      box-sizing: border-box;
    }
    form {
      width: 100%;
      padding: 10px;

      .input-element {
        margin-bottom: 6px;

        label {
          display: block;
          color: #b9b9b9;
          font-size: 12px;
          margin-bottom: 3px;
        }
        input {
          height: 30px;
          width: 100%;
          padding: 0px 5px;
          background-color: #3e3e3e;
          outline: none;
          border: none;
          color: white;
        }
      }
      button {
        width: 100%;
        height: 33px;
        margin-top: 6px;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
