.tab-item {
  height: 31px;
  user-select: none;
  cursor: pointer;
  font-size: 11px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  border-right: 0.5px solid #353535;
  border-bottom: 0.5px solid #353535;
  background-color: rgba(62, 62, 62, 0.3);
  border-top: none;

  &:last-child {
    border-right: 0;
  }
}
.tab-item-active {
  border-bottom: none;
  background: #4792e2;
}
.tabs-container {
  display: flex;
  justify-content: space-evenly;
}
