#dco-table.main-table {
  table {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    color: #ffffff;
    border-collapse: collapse;

    thead {
      background-color: none;
      tr {
        color: white;
        padding-bottom: 5px;
        height: 35px;
        border-bottom: none;
      }
    }
    th {
      font-weight: bold;
      font-size: 14px;
    }
    td {
      margin: 0px;
      font-size: 12px;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      word-break: break-word;
      overflow: hidden;
    }

    tr {
      height: 47.5px;
      border-bottom: 1px solid #494949;
    }

    .bold {
      font-weight: 600;
      width: 150px;
    }

    .column-name {
      width: 146px;
      font-size: 14px;
    }
    input[type='checkbox'] {
      vertical-align: middle;
      margin: 0px;
      height: 15px;
      width: 15px;
    }
  }
}
