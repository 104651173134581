.animation-form-container {
  padding: 0px 20px 10px 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  //width: 250px;
  box-sizing: border-box;
  .blue-background {
    background-color: #305690;
  }
}
.margin-bottom {
  margin-bottom: 10px;
}

.attributes-top-label {
  margin-right: 7px;
  margin-bottom: 10px;
  text-align: center;
}

.attributes-input-big-animation {
  width: 89px;
  margin: 0px;
}

.attributes-input-big {
  width: 100px;
  margin: 0px;
}
