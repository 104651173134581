@import './variables';
@mixin respond-medium {
  @media (min-width: 1600px) {
    @content;
  }
}
.zuuvi-logo {
  display: flex;
  margin: 7px auto 4px;
}

button:hover > .icon-hover,
.dashboard-link:hover > .icon-hover {
  > path {
    fill: $zuuvi-blue;
  }
}
.icon-hover:hover {
  > path {
    fill: $zuuvi-blue;
  }
}
.pintura-editor {
  --color-background: 36, 36, 36 !important;
  --color-foreground: 255, 255, 255 !important;
  --editor-max-width: 72em;
  --editor-max-height: 57.5em;
  --editor-modal-overlay-opacity: 0.7 !important;
  --editor-modal-shadow: 0em 0em 4em rgba(0, 0, 0, 0.6) !important;
  --editor-modal-outline: 10px solid red !important;
  --color-preview-outline: 255, 0, 0;

  .PinturaInputDimension {
    padding-left: 5px;
    padding-right: 5px;
    &label {
      right: 1em;
    }
  }

  .PinturaInputDimension label {
    right: 1em;
  }

  &[data-env^='overlay'] {
    //--color-background: none !important;
    outline: 2px $zuuvi-blue solid !important;
  }

  &[data-env^='overlay'] .PinturaProgressIndicator {
    margin: 0em !important;
    left: -0.62em !important;
  }

  .PinturaButtonExport {
    color: white !important;
    z-index: 9999;
    background-color: $zuuvi-blue !important;
  }

  &[data-env~='overlay'] .PinturaNavTools {
    top: auto !important;
    bottom: 0 !important;
    padding: 0.5em 0.5em 0.5em !important;
  }

  &[data-env~='overlay'] .PinturaUtilFooter {
    pointer-events: none;
  }
}

.margin-right-pointer {
  cursor: pointer;
  margin-right: 10px;
}

.margin-right {
  margin-right: 10px;
}

.text-small {
  color: white;
  font-size: 11px;
  margin: 0;
  @include respond-medium {
    font-size: 12px;
  }
}

.color-white {
  color: white !important;
}

.animation-wrapper {
  display: flex;
  align-items: center;
  height: 50px;
  margin-left: 1px;
  background-color: $default-gray;
}

.timeline-layer-light-line {
  height: 25px;
  width: 100%;
  position: relative;
  margin: auto;
  background-color: #2a2a2a;
}

.flex-display {
  display: flex;
  justify-content: space-evenly;
}

.wrap {
  justify-content: space-between;
  flex-wrap: wrap;
}

.width-full {
  width: 100% !important;
}
// color: #8b8b8b;
.input-text {
  color: white;
  font-size: 12px;
  align-self: center;
}

.attributes-input {
  text-align: right;
  height: 30px;
  font-size: 11px;
  padding: 0px 3px 0px 8px;

  width: 30px;
  background-color: #313131;
  outline: none;
  border: none;
  color: white;
}

select.select-wide-input {
  width: 100%;
  margin-bottom: 15px;
  height: 30px;
  background-color: $light-medium-gray;
  border-radius: 1px;
  color: white;
  padding: 0px 10px;
  border: none;
  outline: none;
}
.flex-center-top-margin {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

input[type='checkbox'] {
  margin-bottom: 0px;
  height: 15px;
  width: 15px;
}

.attributes-form-section {
  padding: 20px;
  align-items: center;
  display: flex;
  font-size: 12px;
  & p {
    margin: 0;
  }
}
.add-button-icon {
  height: 17px;
  width: 17px;
  margin-left: 10px;
}
button.add-button {
  width: 100%;
  height: 33px;
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  p {
    line-height: 12px;
  }
}

.center-add-icon {
  padding-left: 5px;
}

.inline-animation-icon {
  height: 19px;
  width: 16px;
  margin-right: 10px;
}
.text-medium {
  align-self: center;
  font-size: 12px;
  line-height: 13px;
  color: #c0c0c0;
  margin: 0;
}
.flex-center {
  display: flex;
  align-items: center;
}
.align-self-center {
  margin-left: 15px;
  align-self: center;
}
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-scrollbars {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
}
.no-scrollbars::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  margin: 2px;
}

::-webkit-scrollbar-track {
  margin: 5x;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgb(55, 55, 55);
  border-radius: 10px;
  background-clip: content-box;
  border: 1px solid transparent;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.1);
}
