#dco-mini-table {
  padding: 10px;
  padding-bottom: 20px;
  width: 100%;
  table {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 11px;
    color: #ffffff;
    table-layout: fixed;

    width: 100%;

    th,
    td {
      margin: 0px;
    }

    td:last-child,
    th:last-child {
      width: 65%;
      overflow: hidden;
      white-space: nowrap;

      background: -webkit-linear-gradient(left, #fff 85%, #353535);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    td:first-child {
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;

      background: -webkit-linear-gradient(left, #fff 85%, #353535);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    thead {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      text-align: left;

      color: #ffffff;

      th {
        padding-bottom: 2px;
        padding-bottom: 10px;
      }
    }
  }

  .arrow-wrapper {
    position: absolute;
    left: 180px;
  }

  .right-arrow {
    margin-left: 5px;
    width: 16px;
  }

  .left-arrow {
    margin-right: 5px;
    width: 16px;
  }

  .eye-icon {
    padding-right: 5px;
  }
}

#dco-mini-table-custom {
  padding: 10px;
  padding-bottom: 20px;
  width: 100%;
  table {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 11px;
    color: #ffffff;
    table-layout: fixed;

    width: 100%;

    th,
    td {
      margin: 0px;
    }

    td:last-child,
    th:last-child {
      width: 30%;
      overflow: hidden;
      white-space: nowrap;

      background: -webkit-linear-gradient(left, #fff 85%, #353535);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    td:first-child {
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;

      background: -webkit-linear-gradient(left, #fff 85%, #353535);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    thead {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      text-align: left;

      color: #ffffff;

      th {
        padding-bottom: 2px;
        padding-bottom: 10px;
      }
    }
  }

  .arrow-wrapper {
    position: absolute;
    left: 180px;
  }

  .right-arrow {
    margin-left: 5px;
    width: 16px;
  }

  .left-arrow {
    margin-right: 5px;
    width: 16px;
  }

  .eye-icon {
    padding-right: 5px;
  }
}
