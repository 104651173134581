button {
  background: none;
  color: #ffffff;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
}

.add-button {
  width: 100%;
  height: 33px;
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  p {
    line-height: 12px;
  }
}

.add-button-icon {
  height: 17px;
  width: 17px;
  margin-left: 10px;
}
