// Variables
$backgroundColorPrimary: rgb(27, 27, 27);
$backgroundColorSecondary: #242424;
$textColorPrimary: #fff;
$textColorSecondary: #b9b9b9;
$borderColor: #353535;
$zuuviBlue: #4792e2;
$zuuviRed: #df6d4e;

@mixin respond-medium {
  @media (min-width: 1600px) {
    @content;
  }
}

#studio-dashboard {
  background-color: $backgroundColorPrimary;
  color: $textColorPrimary;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
  .right-tab-button {
    width: 100%;
    height: 55px;
    margin-bottom: 10px;
  }
  // Resets
  button {
    color: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    text-transform: inherit;
    text-indent: inherit;
    text-shadow: inherit;
    text-align: inherit;
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
    border: 0;

    &:focus {
      outline: none;
    }
  }

  input {
    &:focus {
      outline: none;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .input-element {
    margin-bottom: 15px;
    label {
      display: block;
      font-size: 14px;
      margin-bottom: 4px;
    }
    input {
      width: 200px;
      height: 30px;
      background: $backgroundColorSecondary;
      border: 1px solid #a0a0a0;
      border-radius: 4px;
      padding: 0 10px;
      color: #fff;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a0a0a0;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a0a0a0;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a0a0a0;
      }
    }
  }

  .button {
    background: $zuuviBlue;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    cursor: pointer;

    &.cancel {
      background: #fc4a4a;
      margin-right: 3px;
      border: 0;
    }

    &.create {
      margin-left: 3px;
      border: 0;
    }
  }

  .input-search {
    width: 200px;
    height: 30px;
    background: $backgroundColorSecondary;
    border-radius: 30px;
    border: 0;
    padding: 0 15px;
    color: #fff;
  }

  #main-container {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .format-options {
    position: relative;
    right: 0px;
    top: 0px;
    width: 100%;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
    .format-options-button {
      position: absolute;
      top: 2px;
      right: 9px;
      padding: 12px;
    }

    .format-options-list {
      position: absolute;
      right: 13px;
      top: 35px;
      width: auto;
      background: #2a2a2a;
      border: 1px solid $borderColor;
      box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.5);
      z-index: 9;
      border-radius: 4px;
      overflow: hidden;

      .format-option-item {
        display: flex;
        align-items: center;
        height: 33px;
        line-height: 22px;
        padding: 10px 15px;
        &:first-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        &:last-child {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }

        &.warning {
          background-color: $zuuviRed;
          display: flex;
          flex-direction: column;
          height: auto;
          max-width: 190px;
        }
        &.delete:hover {
          background-color: $zuuviRed;
        }
        &:hover {
          background-color: $zuuviBlue;
        }
        p {
          margin: 0px;
        }
      }
    }
  }

  #left-menu-wrapper {
    background: $backgroundColorSecondary;
    width: 67px;
    height: 100%;
    border-right: 1px solid $borderColor;
    flex-grow: 0;
    flex-shrink: 0;

    .left-nav-bottom-buttons {
      width: 67px;
      position: absolute;
      bottom: 5px;
      text-align: center;
    }
    .icon-button {
      cursor: pointer;
      background-color: $backgroundColorSecondary !important;
    }
  }

  .left-menu-logo {
    max-width: calc(100% - 16px);
    margin-left: 9px;
    margin-top: 7px;
  }

  #content-wrapper {
    height: 100%;
    width: 100%;
    flex-grow: 1;

    .content-section {
      display: flex;
      padding: 0px 40px;

      &:not(:last-child) {
        border-bottom: 1px solid $borderColor;
      }
    }
  }

  .container {
    width: 100%;
    padding: 0 40px;
  }

  .card-container {
    $cardMargin: 8px;
    display: flex;
    flex-wrap: wrap;
    margin: #{-$cardMargin};
    width: calc(100% + #{$cardMargin * 2});
  }
  .card {
    width: 300px;
    margin: 8px;
    background: $backgroundColorSecondary;
    border-radius: 4px;
    border: 1px solid $borderColor;
    height: 147px;
  }

  #templates-wrapper {
    height: calc(100vh - (85px + 58px));
    .templates-container {
      padding-top: 40px;
      padding-bottom: 40px;
      flex-wrap: wrap;
      .templates-count-text {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
      }

      #templates {
        .template {
          cursor: pointer;
          text-align: center;

          .template-format-count {
            color: $textColorSecondary;
            font-size: 12px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  #templates-container2 {
    width: 100%;
    max-width: 620px;
  }

  #templates {
    // .template {
    //     display: flex;
    //     flex-wrap: wrap;
    //     margin: 10px 0;
    //     padding: 5px 0;
    //     border-top: 1px solid black;
    // }

    .template-id {
      flex-basis: 60%;
      flex-grow: 1;
    }
    .template-actions {
      flex-basis: 40%;
      flex-grow: 1;
    }
    .formats-container {
      flex-basis: 100%;
      flex-grow: 1;
      margin-left: 30px;
      padding-left: 5px;
      border-left: 1px solid black;

      .formats-header {
        font-weight: 700;
      }

      .format {
        display: flex;
        padding: 2px 0;
        margin: 5px 0;
        border-top: 1px solid black;

        .format-id {
          flex-basis: 60%;
          flex-grow: 1;
        }

        .format-size {
          flex-basis: 20%;
          flex-grow: 1;
        }

        .format-actions {
          flex-basis: 20%;
          flex-grow: 1;
        }
      }
    }
  }
}
