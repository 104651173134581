.animations-small-card {
  text-align: center;
  margin: 5px;
  font-size: 11px;
}

.animations-small-card-content {
  width: 66px;
  height: 66px;
  border: 1px solid #353535;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;

  cursor: pointer;

  &:hover {
    border: 0;
    background-color: #4792e2;
  }
}
