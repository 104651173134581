#dco-table.parameter-table {
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #494949;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.03);
    background-color: #3e3e3e;
    text-align: left;
    color: #ffffff;
    font-size: 12px;
    font-weight: normal;

    input {
      background: inherit;
      border: 0;
      width: 100%;
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;

      &:focus {
        outline-width: 0;
      }
    }

    thead {
      background-color: #363636;
      font-size: 11px;
      color: rgba(255, 255, 255, 0.5);
    }

    th,
    td {
      margin: 0px;
      padding: 0.5rem;
      border-bottom: 1px solid #494949;
      border-right: 1px solid #494949;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      word-break: break-word;
      overflow: hidden;
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
